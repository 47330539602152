import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../../pages/LANDING/components/Navbar'
import Footer from '../../pages/LANDING/components/Footer'
import '../../pages/index.css'

const Layout = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      </Helmet>
      <Navbar />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
