exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-click-tracking-software-js": () => import("./../../../src/pages/click-tracking-software.js" /* webpackChunkName: "component---src-pages-click-tracking-software-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-landing-components-banner-jsx": () => import("./../../../src/pages/LANDING/components/Banner.jsx" /* webpackChunkName: "component---src-pages-landing-components-banner-jsx" */),
  "component---src-pages-landing-components-branded-jsx": () => import("./../../../src/pages/LANDING/components/Branded.jsx" /* webpackChunkName: "component---src-pages-landing-components-branded-jsx" */),
  "component---src-pages-landing-components-footer-jsx": () => import("./../../../src/pages/LANDING/components/Footer.jsx" /* webpackChunkName: "component---src-pages-landing-components-footer-jsx" */),
  "component---src-pages-landing-components-navbar-jsx": () => import("./../../../src/pages/LANDING/components/Navbar.jsx" /* webpackChunkName: "component---src-pages-landing-components-navbar-jsx" */),
  "component---src-pages-landing-components-partner-logos-jsx": () => import("./../../../src/pages/LANDING/components/PartnerLogos.jsx" /* webpackChunkName: "component---src-pages-landing-components-partner-logos-jsx" */),
  "component---src-pages-landing-components-partner-sec-jsx": () => import("./../../../src/pages/LANDING/components/PartnerSec.jsx" /* webpackChunkName: "component---src-pages-landing-components-partner-sec-jsx" */),
  "component---src-pages-landing-components-platform-jsx": () => import("./../../../src/pages/LANDING/components/Platform.jsx" /* webpackChunkName: "component---src-pages-landing-components-platform-jsx" */),
  "component---src-pages-landing-components-reviews-jsx": () => import("./../../../src/pages/LANDING/components/Reviews.jsx" /* webpackChunkName: "component---src-pages-landing-components-reviews-jsx" */),
  "component---src-pages-landing-components-tabs-jsx": () => import("./../../../src/pages/LANDING/components/Tabs.jsx" /* webpackChunkName: "component---src-pages-landing-components-tabs-jsx" */),
  "component---src-pages-landing-components-timeline-jsx": () => import("./../../../src/pages/LANDING/components/Timeline.jsx" /* webpackChunkName: "component---src-pages-landing-components-timeline-jsx" */),
  "component---src-pages-landing-landingpage-landing-page-jsx": () => import("./../../../src/pages/LANDING/landingpage/LandingPage.jsx" /* webpackChunkName: "component---src-pages-landing-landingpage-landing-page-jsx" */),
  "component---src-pages-landing-svg-timeline-svg-jsx": () => import("./../../../src/pages/LANDING/svg/TimelineSvg.jsx" /* webpackChunkName: "component---src-pages-landing-svg-timeline-svg-jsx" */),
  "component---src-pages-no-default-redirect-js": () => import("./../../../src/pages/no-default-redirect.js" /* webpackChunkName: "component---src-pages-no-default-redirect-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-qr-code-generator-js": () => import("./../../../src/pages/qr-code-generator.js" /* webpackChunkName: "component---src-pages-qr-code-generator-js" */),
  "component---src-pages-release-notes-jsx": () => import("./../../../src/pages/release-notes.jsx" /* webpackChunkName: "component---src-pages-release-notes-jsx" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-utc-time-js": () => import("./../../../src/pages/utc-time.js" /* webpackChunkName: "component---src-pages-utc-time-js" */),
  "component---src-templates-support-template-js": () => import("./../../../src/templates/supportTemplate.js" /* webpackChunkName: "component---src-templates-support-template-js" */)
}

