import React from 'react'

const YTIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <rect
        x="2.625"
        y="4.625"
        width="15.75"
        height="12.25"
        rx="4"
        stroke="#3C3E49"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.75 8.125L13.125 10.75L8.75 13.375V8.125Z"
        stroke="#3C3E49"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default YTIcon
