import React from 'react'

const StyleBar = ({ heightClass }) => {
  return (
    <div className="flex">
      <span className={`${heightClass} flex-1 bg-[#ABF0FF]`}></span>
      <span className={`${heightClass} flex-1 bg-[#EBACFF]`}></span>

      <span className={`${heightClass} flex-1 bg-[#C2FFAB]`}></span>
      <span className={`${heightClass} flex-1 bg-[#BEC7FF]`}></span>
      <span className={`${heightClass} flex-1 bg-[#FFE57F]`}></span>
      <span className={`${heightClass} flex-1 bg-[#FF9889]`}></span>
      <span className={`${heightClass} flex-1 bg-[#ABFFD4]`}></span>
    </div>
  )
}

export default StyleBar
