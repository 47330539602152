import React from 'react'

const XIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        d="M15.1016 3.57275H17.5367L12.2168 9.65298L18.4752 17.9268H13.575L9.73691 12.9088L5.3453 17.9268H2.9088L8.59889 11.4233L2.59521 3.57275H7.61987L11.0891 8.15943L15.1016 3.57275ZM14.247 16.4693H15.5963L6.88671 4.95369H5.43879L14.247 16.4693Z"
        fill="#3C3E49"
      />
    </svg>
  )
}

export default XIcon
