import React from 'react'

const FBIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        d="M6.125 9V12.5H8.75V18.625H12.25V12.5H14.875L15.75 9H12.25V7.25C12.25 6.76675 12.6418 6.375 13.125 6.375H15.75V2.875H13.125C10.7088 2.875 8.75 4.83375 8.75 7.25V9H6.125"
        stroke="#3C3E49"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default FBIcon
